@use '~summit-react/src/scss/variables' as v;

.releases {
  margin-top: 20px;

  .heading {
    font-weight: bold;
  }

  .release.empty {
    text-align: center;
    font-size: large;
    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .spinner {
    text-align: center;
    font-size: xx-large;
  }
}
