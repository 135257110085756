@use '~summit-react/src/scss/variables' as v;

.status-label {
  white-space: nowrap;
}
.status-icon {
  padding-right: 0.25em;

  .red {
    color: v.$red;
  }

  .blue {
    color: v.$architecture-blue;
  }

  .green {
    color: v.$green;
  }

  .hidden {
    display: none;
  }
}
