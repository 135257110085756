@use '~summit-react/src/scss/variables' as v;

.release {
  font-family: v.$text-font-family;

  .release-header.card-header {
    background-color: white;
  }

  .release-actions {
    .row {
      align-items: center;
    }
    .fa-spinner {
      font-size: x-large;
      vertical-align: middle;
    }
    margin-top: 10px;
    .non-destructive {
      .btn {
        margin-right: 5px;
      }
    }
    .progress-message {
      padding-left: 2rem;
    }
    .action {
      text-align: right;
      .btn {
        margin-left: 5px;
      }
    }
  }

  .btn.destroy {
    background-color: v.$red;
  }

  .btn.retry {
    background-color: v.$warning-orange;
  }

  .collapse-control {
    padding: 0 0.25rem 0 0.25rem;
  }

  .prop-label {
    color: v.$astrogranite-gray;
  }
}

span.tag {
  white-space: nowrap;
}
